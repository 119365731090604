import React, { PureComponent } from 'react'

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { IntlProvider, addLocaleData } from 'react-intl'

import { StandardLayoutProps } from './types'

import 'styles/bootstrap.css'
import 'styles/estilo.css'

import 'styles/forms.css'
import 'styles/formsy.css'
import 'styles/radio.css'
import 'styles/tooltip.css'
import 'styles/bus-map.css'
import 'styles/progress.css'
import 'moment/locale/es'

import { localeData, localeMessages } from 'i18n'
import moment from 'moment'
import { Footer } from '../../../components'

import ButttonActionBuy from 'core/components/buttonActionBuy/buttonActionBuy'

import { Container, Row, Col } from 'reactstrap'
import { Go } from 'shared/components/common'
import NewHeader from 'core/components/NewHeader/NewHeader'
addLocaleData(localeData)

class StandardLayout extends PureComponent<StandardLayoutProps> {
  render() {
    const { children, locale } = this.props
    moment.locale(locale)
    const translates = localeMessages[locale]
    return (
      <StaticQuery
        query={graphql`
          query IndexLayoutQuery {
            site {
              siteMetadata {
                title
                description
                keywords
                revisitAfter
                favicon
              }
            }
          }
        `}
        render={(data: StaticQueryProps) => {
          let { title, description, keywords, revisitAfter, favicon } = data.site.siteMetadata
          title = translates[title]
          description = translates[description]
          keywords = translates[keywords]
          revisitAfter = revisitAfter || '10 days'
          favicon = favicon || '/icons/favicon.ico'
          return (
            <IntlProvider locale={locale} messages={translates}>
              <React.Fragment>
                <Helmet
                  title={title}
                  htmlAttributes={{ lang: locale }}
                  meta={[
                    { name: 'description', content: description },
                    { name: 'keywords', content: keywords },
                    { name: 'robots', content: 'index, follow' },
                    { httpEquiv: 'Content-Language', content: locale },
                    { name: 'revisit-after', content: revisitAfter }
                  ]}
                  link={[{ rel: 'icon', href: favicon, type: 'image/x-icon' }]}
                />
                {/* <Header /> */}
                <NewHeader />
                {/* <ButttonAction /> */}

                <main className="w-100">{children}</main>

                <ButttonActionBuy />
                <Footer />
                <Container className="legal" fluid={true}>
                  <div style={{ maxWidth: '1400px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Row>
                      <Col sm={7}>
                        <span>© 2023 Autotransportes y Carga All Abordo. Todos los derechos reservados</span>
                      </Col>
                      <Col sm={5} className="text-right d-none d-sm-block">
                        <span>
                          <Go to="/avisolegal">Aviso legal</Go>
                        </span>{' '}
                        <span>
                          <Go to="/avisoprivacidad">Aviso de privacidad</Go>
                        </span>
                        <span>
                          <Go to="/terminos">Términos y condiciones</Go>
                        </span>
                      </Col>
                      <Col sm={5} className="d-block d-sm-none">
                        <span>
                          <Go to="/avisolegal">Aviso legal</Go>
                        </span>
                        <span>
                          <Go to="/avisoprivacidad">Aviso de privacidad</Go>
                        </span>
                        <span>
                          <Go to="/terminos">Términos y condiciones</Go>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </React.Fragment>
            </IntlProvider>
          )
        }}
      />
    )
  }
}

export default StandardLayout
