import React, { useState, useEffect } from 'react'
import { Navbar, NavbarBrand, Nav, Button } from 'reactstrap'
import { Link, navigate } from 'gatsby'
import AllAbordoLogo from 'img/AllAbordoBlanco.svg'
import chatIcon from 'img/chat-icon.png'

const MyNavbar = ({ isScrolled }: any) => {
  const [, setIsOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const handleSidebarToggle = () => setIsSidebarOpen(prev => !prev)

  const navigateTo = (path: string) => {
    const isHomePage = window.location.pathname === '/'

    if (path.startsWith('/#')) {
      if (isHomePage) {
        setTimeout(() => {
          const elementId = path.split('#')[1]
          const element = document.getElementById(elementId)
          if (element) {
            const offset = -120
            const topPos = element.getBoundingClientRect().top + window.pageYOffset + offset
            window.scrollTo({ top: topPos, behavior: 'smooth' })
          }
        }, 50)
      } else {
        navigate('/')
        setTimeout(() => {
          const elementId = path.split('#')[1]
          const element = document.getElementById(elementId)
          if (element) {
            const offset = -120
            const topPos = element.getBoundingClientRect().top + window.pageYOffset + offset
            window.scrollTo({ top: topPos, behavior: 'smooth' })
          }
        }, 50)
      }
    } else {
      navigate(path)
    }
    setIsOpen(false)
  }

  const closeSidebar = () => {
    setIsSidebarOpen(false)
  }

  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200)
  }

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200) // Configura el estado inicial al montar
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Navbar
        className="navbar"
        expand="md"
        style={{
          padding: isScrolled ? '0' : '1rem',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <NavbarBrand onClick={() => navigateTo('/')}>
          <img src={AllAbordoLogo} alt="logo" style={{ maxHeight: isMobile ? '30px' : '50px', cursor: 'pointer' }} />
        </NavbarBrand>
        <Nav className="ml-auto" navbar style={{ display: isMobile ? 'none' : 'flex' }}>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center'
            }}
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                borderLeft: '4px solid #d01d56',
                height: '40px',
                outline: 'none !important'
              }}
              onClick={() => navigateTo('/#destinos')}
            >
              NUESTROS DESTINOS
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                borderLeft: '4px solid #d01d56',
                height: '40px',
                outline: 'none !important'
              }}
              onClick={() => navigateTo('/#amenidades')}
            >
              AMENIDADES
            </Button>
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                borderLeft: '4px solid #d01d56',
                height: '40px',
                outline: 'none !important'
              }}
              onClick={() => navigateTo('/#terminales')}
            >
              TERMINALES
            </Button>
            {isMobile ? (
              // Botón de menú para la versión móvil
              <Button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  borderLeft: '4px solid #d01d56',
                  height: '40px',
                  outline: 'none !important'
                }}
                onClick={handleSidebarToggle}
              >
                MENÚ
              </Button>
            ) : (
              // Botón de facturación para versiones de escritorio
              <Button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  borderLeft: '4px solid #d01d56',
                  height: '40px',
                  outline: 'none !important'
                }}
                onClick={() => navigateTo('/solicitarfactura')}
              >
                FACTURACIÓN
              </Button>
            )}
            <Button
              onClick={() => navigateTo('/rastreo')}
              style={{ backgroundColor: '#d01d56', border: '5px solid white', borderRadius: '10px' }}
            >
              PAQUETERÍA Y MENSAJERÍA
              <br />
              RASTREOS
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Button
              style={{ backgroundColor: 'transparent', border: 'none', height: '40px', outline: 'none !important' }}
              onClick={() => navigateTo('/iniciarsesion')}
            >
              Iniciar sesión
            </Button>
            <Button
              style={{ backgroundColor: 'transparent', border: 'none', height: '40px', outline: 'none !important' }}
              onClick={() => navigateTo('/registrousuario')}
            >
              Registro
            </Button>
          </div>
        </Nav>
        <Button
          onClick={handleSidebarToggle}
          style={{ display: isMobile ? 'block' : 'none', backgroundColor: 'transparent', border: 'none', fontSize: '30px' }}
        >
          &#9776;
        </Button>
      </Navbar>
      <div
        style={{
          opacity: isSidebarOpen ? 1 : 0, // Opacidad 1 cuando el sidebar está abierto, 0 cuando está cerrado
          pointerEvents: isSidebarOpen ? 'auto' : 'none', // Permite clics solo cuando el sidebar está abierto
          transition: 'opacity 0.3s ease', // Transición suave de la opacidad
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99998
        }}
        onClick={closeSidebar}
      />

      <div
        style={{
          position: 'fixed',
          top: 0,
          right: isSidebarOpen ? 0 : '-100%',
          width: '80%',
          maxWidth: '400px',
          height: '100vh',
          backgroundColor: '#27387e',
          transition: 'right 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: '15px',
          zIndex: 99999,
          paddingLeft: '40px',
          paddingTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '15px'
          }}
        >
          <NavbarBrand onClick={() => navigateTo('/')} style={{ paddingBottom: '50px', marginLeft: 0 }}>
            <img src={AllAbordoLogo} alt="logo" width="200px" />
          </NavbarBrand>
          {/* Botones del menú lateral */}
          <Button
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              backgroundColor: '#fff',
              borderRadius: '100px',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer'
            }}
            onClick={closeSidebar}
          >
            &#10006;
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderLeft: '4px solid #d01d56',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/#destinos')}
          >
            NUESTROS DESTINOS
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderLeft: '4px solid #d01d56',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/#amenidades')}
          >
            AMENIDADES
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderLeft: '4px solid #d01d56',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/#terminales')}
          >
            TERMINALES
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderLeft: '4px solid #d01d56',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/solicitarfactura')}
          >
            FACTURACIÓN
          </Button>
          <Button
            onClick={() => navigateTo('/rastreo')}
            style={{ backgroundColor: '#d01d56', border: '5px solid white', borderRadius: '10px' }}
          >
            PAQUETERÍA Y MENSAJERÍA
            <br />
            RASTREOS
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/iniciarsesion')}
          >
            Iniciar sesión
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              height: '40px',
              outline: 'none !important'
            }}
            onClick={() => navigateTo('/registrousuario')}
          >
            Registro
          </Button>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Link to="/">
            <Button
              color="primary"
              style={{
                borderRadius: '15px',
                width: '250px',
                height: '60px',
                fontSize: '16px',
                backgroundColor: '#d01d56',
                border: 'none',
                marginBottom: '10px'
              }}
              onClick={() => setIsSidebarOpen(false)}
            >
              COMPRA TU BOLETO
            </Button>
          </Link>
          <Link to="https://allabordo-chat.web.app/demo/NhJJmv3nHOZxunycGc2M" target="_blank" rel="noopener noreferrer">
            <Button
              color="primary"
              style={{
                borderRadius: '15px',
                width: '250px',
                height: '60px',
                fontSize: '16px',
                backgroundColor: '#27387e',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Chatea con nosotros
              <img
                src={chatIcon}
                alt="Chat icon"
                style={{
                  marginLeft: '8px',
                  height: '25px',
                  width: '25px'
                }}
              />
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default MyNavbar
