import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import imgAllAbordoBlanco from 'img/AllAbordoBlanco.svg'
import { FooterStyle, FooterProps } from './types'
import { Go } from 'shared/components/common'
import { Col, Container } from 'reactstrap'
import imgFaqInstagram from 'img/IG.svg'
import imgFaqfacebook from 'img/facebook.svg'
import imgFaqTw from 'img/tw.svg'
import Row from 'reactstrap/lib/Row'
import './Footer.css'

const styles: FooterStyle = () => ({
  abordoBlanco: {
    width: '90%'
  }
})

interface ISregister {
  showingQuestions: boolean
  showingAbout: boolean
  showingContact: boolean
}

class Footer extends PureComponent<FooterProps, ISregister> {
  state: ISregister = {
    showingQuestions: false,
    showingAbout: false,
    showingContact: false
  }

  render() {
    let { classes } = this.props
    classes = classes || {}

    return (
      <footer>
        <Container fluid={true} style={{ maxWidth: '1400px' }}>
          <Row className="text-center" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Col sm={12} md={3} className="redes" style={{ marginBottom: '30px', textAlign: 'center' }}>
              <img
                style={{ width: '90%', maxWidth: '250px' }}
                src={imgAllAbordoBlanco}
                className={classNames(classes.abordoBlanco, 'img-responsive')}
              />
              <br />
              <br />
              <a href="https://www.instagram.com/viaja.abordo/" target="blank">
                <img src={imgFaqInstagram} />
              </a>
              <a href="https://www.facebook.com/viajaABORDO/" target="blank">
                <img src={imgFaqfacebook} />
              </a>
              <a href="https://twitter.com/AbordoAll" target="blank">
                <img src={imgFaqTw} />
              </a>
            </Col>
            <Col sm={12} md={2}>
              <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                <Go to="/nosotros">
                  <h5>Acerca de nosotros</h5>
                </Go>
                <Go to="/compraboleto">
                  <li>Autotransportes</li>
                </Go>
                {/* <Go to="/tours">
                  <li>Vans</li>
                </Go> */}
                <Go to="/cotizarenvio">
                  <li>Paquetería</li>
                </Go>
              </ul>
            </Col>
            <Col sm={12} md={2}>
              <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                <li>
                  <h5 style={{ color: 'transparent' }}>-</h5>
                </li>
                <Go to="/destinos">
                  <li>Destinos</li>
                </Go>
                <Go to="/estatuscorrida">
                  <li>Estatus de Corrida</li>
                </Go>
                <Go to="/rastreo">
                  <li>Rastrear Paquete</li>
                </Go>
              </ul>
            </Col>
            <Col sm={12} md={2}>
              <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                <li>
                  <h5 style={{ color: 'transparent' }}>-</h5>
                </li>
                <Go to="/faq">
                  <li>Preguntas frecuentes</li>
                </Go>
                <Go to="/politicasviaje">
                  <li>Políticas de viaje</li>
                </Go>
                <Go to="/solicitarfactura">
                  <li>Tu Factura</li>
                </Go>
              </ul>
            </Col>
            <Col sm={12} md={2}>
              <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                <li>
                  <h5 style={{ color: 'white' }}>Contacto</h5>
                </li>
                <li style={{ color: 'white' }}>
                  <a href="https://wa.me/5216681460984" style={{ textDecoration: 'none', color: 'white' }}>
                    WhatsApp
                  </a>
                </li>
                <li style={{ color: 'white' }}>
                  <a href="tel:+5216681460984" style={{ textDecoration: 'none', color: 'white' }}>
                    (668) 146 09 84
                  </a>
                </li>
                <li style={{ color: 'white' }}>
                  <a href="/contacto" style={{ textDecoration: 'none', color: 'white' }}>
                    viaja@allabordo.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

export default injectSheet(styles)(Footer)
