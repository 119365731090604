import React, { Component } from 'react'
import './buttonActionBuy.css'
import { Link } from 'gatsby'
import { Button } from 'reactstrap'
import chatIcon from 'img/chat-icon.png'

interface BuyState {
  posicionBuy: Number
}

class ButttonActionBuy extends Component<any, BuyState> {
  state = {
    posicionBuy: 0
  }

  constructor(props: any) {
    super(props)
    this.setState({
      posicionBuy: 0
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
    this.setState({
      posicionBuy: 0
    })
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = winScroll / height
    this.setState({
      posicionBuy: scrolled
    })
  }

  render() {
    const { posicionBuy } = this.state
    return (
      <>
        {posicionBuy > 0.18 ? (
          <div className="ButtonFloatBuy" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Link to="/">
              <Button
                color="primary"
                style={{
                  borderRadius: '15px',
                  width: '150px',
                  fontSize: '14px',
                  backgroundColor: '#d01d56',
                  border: 'none',
                  marginBottom: '10px'
                }}
              >
                Compra aquí tu boleto
              </Button>
            </Link>
            <Link
              className="ButtonText"
              to="https://allabordo-chat.web.app/demo/NhJJmv3nHOZxunycGc2M"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                color="primary"
                style={{
                  borderRadius: '15px',
                  width: '150px',
                  fontSize: '14px',
                  backgroundColor: '#27387e',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                Chatea con nosotros
                <img
                  src={chatIcon}
                  alt="Chat icon"
                  style={{
                    marginLeft: '8px',
                    height: '25px',
                    width: '25px'
                  }}
                />
              </Button>
            </Link>
          </div>
        ) : null}
      </>
    )
  }
}

export default ButttonActionBuy
