import React, { Component } from 'react'

import classNames from 'classnames'

import classes from './Header.module.css'

import loginImg from 'img/vectores/inicio-ses.svg'
import rastreaImg from 'img/Shape-september-change.svg'
import AllAbordoLogo from 'img/logo_noviembre_2020.svg'
import factura from 'img/factura-september-change.svg'
import AbordoTelefono from 'img/phone-september-change.svg'

import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavbarToggler,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { HeaderProps, HeaderState } from './types'
import { Go, AButton } from 'shared/components/common'
import { navigate } from 'gatsby'
import { AuthService } from 'auth/services'

class Header extends Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    open: false
  }

  constructor(props: HeaderProps) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }

  closeSesion = () => {
    navigate('/iniciarsesion')
    AuthService.logout()
    this.props.removeUser()
  }
  registerUser = () => {
    navigate('/registrousuario')
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { user } = this.props
    return (
      <header className="sticky-top border-header">
        <Container fluid className="sesion">
          <Row>
            <Col sm={4} md={6} className="d-none d-lg-block">
              <Go to="/estatuscorrida">
                <span>Estatus de corrida</span>
              </Go>{' '}
              <Go to="/destinos">
                <span>Destinos</span>
              </Go>{' '}
              <Go to="/alianzas">
                <span>Alianzas</span>
              </Go>{' '}
              <span>
                {' '}
                <Go to="/centrales">
                  <span>Contacto</span>
                </Go>
              </span>{' '}
              <span>
                {' '}
                <Go to="/descarga">
                  <img style={{ width: '20px', height: '20px' }} src={AbordoTelefono} />
                  <span>Nuestra app</span>
                </Go>
              </span>
            </Col>
            <Col sm={3} md={1} className="d-none d-lg-block" />
            <Col sm={12} md={5} xs={12} className={'text-right'}>
              <span>
                {user ? (
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle tag="div" caret style={{ cursor: 'pointer' }}>
                      <img src={loginImg} className="img-responsive" />
                      {user.name}
                    </DropdownToggle>
                    <DropdownMenu style={{ zIndex: 99999 }}>
                      <DropdownItem>
                        <Go to="/editarperfil">Editar Perfil</Go>
                      </DropdownItem>
                      <DropdownItem>
                        <Go to="/cambiarcontraseña">Cambiar contraseña</Go>
                      </DropdownItem>
                      <DropdownItem>
                        <Go to="/historialviajes">Ver historial de viajes</Go>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <Go to="/iniciarsesion">
                    <img src={loginImg} className="img-responsive" />
                    Iniciar sesión
                  </Go>
                )}
              </span>
              <span>{user ? <a onClick={this.closeSesion}>Cerrar Sesión</a> : <a onClick={this.registerUser}>Registro</a>}</span>
            </Col>
          </Row>
        </Container>

        <Navbar className="border-top-navbar" color="light" light expand="lg">
          <NavbarBrand
            tag={() => (
              <Go className="navbar-brand" to="/">
                <img style={{ width: '200px' }} src={AllAbordoLogo} className="img-responsive" />
              </Go>
            )}
          />
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.open} navbar>
            <Nav className="mr-auto" navbar />

            <ul className="text-right m-0">
              <Go className="d-none d-lg-block quitar-underline" to="/solicitarfactura">
                <div id="first-columna" className="wrapper-ul">
                  <div className="sidebar-icon">
                    <img src={factura} className=" sidebar-icon img-responsive" />
                  </div>

                  <div className="column-text">
                    <b style={{ fontSize: 'medium', textDecoration: 'none' }}>Solicita tu factura</b>

                    <p style={{ fontSize: 'small' }}>con tu código único</p>
                  </div>
                </div>
              </Go>
            </ul>

            <ul className="text-right m-0">
              <Go className="d-none d-lg-block quitar-underline" to="/rastreo">
                <div id="second-columna" className="wrapper-ul">
                  <div className="sidebar-icon">
                    <img src={rastreaImg} className="img-responsive" />
                  </div>
                  <div className="column-text">
                    <b style={{ fontSize: 'medium' }}>Rastrea</b>

                    <p style={{ fontSize: 'small' }}>tu paquete</p>
                  </div>
                </div>
              </Go>
            </ul>

            <ul className={classNames(classes.clearListStyle, 'submenu d-block d-lg-none')}>
              <li>
                <Go to="/solicitarfactura">Solicita tu factura</Go>
              </li>
              <li>
                <Go to="/rastreo">Rastrea tu paquete</Go>
              </li>
              <li>
                <Go to="/compraboleto">Compra tu boleto</Go>
              </li>
              {/* <li>
                <Go to="/tours">Renta un vehículo</Go>
              </li> */}
              <li>
                <Go to="/cotizarenvio">Cotiza tu envío</Go>
              </li>
              <li>
                <Go to="/contacto">Contáctanos</Go>
              </li>
              <li>
                <Go to="/centrales">Nuestras Terminales</Go>
              </li>
            </ul>
          </Collapse>
        </Navbar>

        <Container fluid className="submenu d-none d-lg-block">
          <Go to="/compraboleto">
            <span>Compra tu boleto</span>
          </Go>
          {/* <Go to="/tours">
            <span>Renta un vehículo</span>
          </Go> */}
          <Go to="/cotizarenvio">
            <span>Cotiza tu envío</span>
          </Go>
          <Go to="/contacto">
            <span>Contáctanos</span>
          </Go>
          <Go className="" to="/centrales">
            <AButton variant="pink" className="m-0 nuestras-terminales">
              Nuestras Terminales
            </AButton>
          </Go>
        </Container>
      </header>
    )
  }
}

export default Header
